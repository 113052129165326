.loader {
  display: inline-block;
}

.loader__svg {
  animation: rotate 2s linear infinite;
}

.loader__svg .path {
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 120;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 120;
    stroke-dashoffset: -25;
  }
  100% {
    stroke-dasharray: 90, 120;
    stroke-dashoffset: -100;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
