@font-face {
  font-family: Common Sans;
  src: url("thin.e6a8c3db.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Common Sans;
  src: url("extralight.46f28b6b.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Common Sans;
  src: url("light.6b5b0b07.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Common Sans;
  src: url("regular.d5c5c378.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Common Sans;
  src: url("medium.8cb35e24.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Common Sans;
  src: url("demibold.d8cd8362.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Common Sans;
  src: url("bold.38c242a4.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Common Sans;
  src: url("black.d331d5b5.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

.button {
  font: var(--font-button);
  cursor: pointer;
  width: fit-content;
  min-width: 300px;
  min-height: 52px;
  margin: var(--typo-margin);
  border: 1px solid;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 4px 50px;
  text-decoration: none;
  transition: all .3s;
  display: inline-flex;
  position: relative;
}

.button:hover {
  opacity: .75;
}

.button--secondary {
  color: var(--color-primary);
  background-color: #0000;
  border-color: #bebebe;
}

.button--primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}

.button ~ .button {
  margin-top: 0;
}

.button__loader, .button__check {
  position: absolute;
  top: 10px;
  left: 10px;
}

.button--disabled {
  pointer-events: none;
  color: #979797;
  background-color: #ddd;
  border-color: #ddd;
}

.loader {
  display: inline-block;
}

.loader__svg {
  animation: 2s linear infinite rotate;
}

.loader__svg .path {
  stroke-linecap: round;
  animation: 1.5s ease-in-out infinite dash;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1 120;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 120;
    stroke-dashoffset: -25px;
  }

  100% {
    stroke-dasharray: 90 120;
    stroke-dashoffset: -100px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.footer {
  text-align: center;
  padding: 1rem;
  font-size: .8rem;
}

.footer__logos {
  align-items: center;
  gap: 1rem;
  margin: .25rem 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.footer__logos li {
  flex-shrink: 0;
}

:root {
  --font-family: "Common Sans", Helvetica, sans-serif;
  --color-primary: #1a1a1a;
  --color-muted: #595959;
  --font-button: 600 14px / 22px var(--font-family);
  --font-regular: 400 14px / 20px var(--font-family);
  --font-sublogo: 400 14px / 22px var(--font-family);
  --typo-margin: 1rem 0;
  --typo-margin-bigger: 1.75rem 0;
  --typo-margin-smaller: .75rem 0;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  font: var(--font-regular);
  color: var(--color-muted);
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: grid;
}

a {
  color: inherit;
}

h1 {
  color: var(--color-primary);
  margin: var(--typo-margin-bigger);
  font-size: 24px;
  font-weight: 400;
  line-height: 18px;
}

b {
  font-weight: 600;
}

p {
  margin: var(--typo-margin-smaller);
}

form {
  width: 100%;
  max-width: 300px;
  margin: 2rem auto 1rem;
}

input[type="submit"] {
  margin-left: auto;
  margin-right: auto;
}

input[type="email"] {
  width: 100%;
  font: inherit;
  border: 1px solid #c3c8cf;
  border-radius: 3px;
  padding: 15px;
  display: inline-block;
}

.root {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.root > [data-controller] {
  text-align: center;
  max-width: 720px;
}

.text-sublogo {
  font: var(--font-sublogo);
  color: var(--color-primary);
}

.graphics-container {
  justify-content: center;
  align-items: center;
  height: 280px;
  display: flex;
}

.graphics-container--smaller {
  height: 90px;
}

.text-align-center {
  text-align: center;
}

.back-button {
  text-decoration: none;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
}

.error, .toast {
  align-items: center;
  gap: .25rem;
  display: inline-flex;
}

.error > *, .toast > * {
  flex-shrink: 0;
}

.error {
  color: #f0404b;
}

.toast {
  color: #1a1a1a;
}
/*# sourceMappingURL=index.9fdee6ce.css.map */
