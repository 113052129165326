@import './fonts.css';
@import '../templates/button.css';
@import '../templates/loader.css';
@import '../templates/footer.css';

:root {
  --font-family: 'Common Sans', Helvetica, sans-serif;

  --color-primary: #1a1a1a;
  --color-muted: #595959;

  --font-button: 600 14px/22px var(--font-family);
  --font-regular: 400 14px/20px var(--font-family);
  --font-sublogo: 400 14px/22px var(--font-family);

  --typo-margin: 1rem 0;
  --typo-margin-bigger: 1.75rem 0;
  --typo-margin-smaller: .75rem 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font: var(--font-regular);
  color: var(--color-muted);
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

a {
  color: inherit;
}

h1 {
  color: var(--color-primary);
  font-size: 24px;
  line-height: 18px;
  font-weight: 400;
  margin: var(--typo-margin-bigger);
}

b {
  font-weight: 600;
}

p {
  margin: var(--typo-margin-smaller);
}

form {
  max-width: 300px;
  width: 100%;
  margin: 2rem auto 1rem auto;
}

input[type="submit"] {
  margin-left: auto;
  margin-right: auto;
}

input[type="email"] {
  display: inline-block;
  width: 100%;
  border: 1px solid #C3C8CF;
  font: inherit;
  border-radius: 3px;
  padding: 15px;
}

.root {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  justify-content: center;
}

.root > [data-controller] {
  max-width: 720px;
  text-align: center;
}

.text-sublogo {
  font: var(--font-sublogo);
  color: var(--color-primary);
}

.graphics-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
}

.graphics-container--smaller {
  height: 90px;
}

.text-align-center {
  text-align: center;
}

.back-button {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  text-decoration: none;
}

.error,
.toast {
  display: inline-flex;
  gap: .25rem;
  align-items: center;
}

.error > *,
.toast > * {
  flex-shrink: 0;
}

.error { color: #F0404B; }
.toast { color: #1A1A1A; }
