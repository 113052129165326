.footer {
  padding: 1rem;
  text-align: center;
  font-size: .8rem;
}

.footer__logos {
  padding: 0;
  margin: .25rem 0;
  display: inline-flex;
  list-style: none;
  gap: 1rem;
  align-items: center;
}

.footer__logos li {
  flex-shrink: 0;
}
