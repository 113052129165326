.button {
  font: var(--font-button);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;
  padding: 4px 50px;
  min-height: 52px;
  min-width: 300px;
  width: fit-content;
  transition: all .3s ease;
  border: 1px solid;
  margin: var(--typo-margin);
  position: relative;
}

.button:hover {
  opacity: .75;
}

.button--secondary {
  background-color: transparent;
  border-color: #BEBEBE;
  color: var(--color-primary);
}

.button--primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #ffffff;
}

.button ~ .button {
  margin-top: 0;
}

.button__loader,
.button__check {
  position: absolute;
  left: 10px;
  top: 10px;
}

.button--disabled {
  pointer-events: none;
  background-color: #DDDDDD;
  border-color: #DDDDDD;
  color: #979797;
}
